import { ButtonGroup, Button } from '@mui/material';

const ButtonGroupActivities = ({ setActivityTypeFilter, currentActivityTypeFilter }) => {
  // This function handles changing the activity type filter
  const handleActivityTypeChange = (type) => {
    // console.log('Activity Type Changed to:', type);
    setActivityTypeFilter(type);
  };

  return (
    <ButtonGroup variant="contained" color="secondary" aria-label="activity type button group" fullWidth sx={{ '& .MuiButton-root': { border: 1, borderColor: 'secondary.dark', borderRadius: 0 } }}>
     
      <Button 
        onClick={() => handleActivityTypeChange('Music')}
        sx={{ backgroundColor: currentActivityTypeFilter === 'Music' ? 'secondary.dark' : 'secondary.main' }}
      >
        Music
      </Button>
      <Button 
        onClick={() => handleActivityTypeChange('Activities')}
        sx={{ backgroundColor: currentActivityTypeFilter === 'Activities' ? 'secondary.dark' : 'secondary.main' }}
      >
        Activities
      </Button>
      <Button 
        onClick={() => handleActivityTypeChange('Art')}
        sx={{ backgroundColor: currentActivityTypeFilter === 'Art' ? 'secondary.dark' : 'secondary.main' }}
      >
        Art
      </Button>
      <Button 
        onClick={() => handleActivityTypeChange('Vendors')}
        sx={{ backgroundColor: currentActivityTypeFilter === 'Vendors' ? 'secondary.dark' : 'secondary.main' }}
      >
        Vendors
      </Button>
      
    </ButtonGroup>
  );
};

export default ButtonGroupActivities;
