import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const EventCardPast = () => {
  return (
    <Card sx={{ backgroundColor: 'secondary.main', color: 'primary.contrastText', p: 0, m:0, marginBottom: '10px' }}>
    <CardContent sx={{ 
       p:'3px', m: 0, 
      paddingBottom: '0px !important',  // Using !important to override any existing styles
      '&:last-child': { paddingBottom: '5px !important' }  // Ensuring it applies to last-child specifically
    }}>
      <Typography variant="h1" component="div" align="center" sx={{ fontSize: '1.25rem', textTransform: 'none', p: 0, m: 0 }}>
        Past Activities
      </Typography>
    </CardContent>
  </Card>
  );
};

export default EventCardPast;