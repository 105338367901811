import React from 'react';
// import { useNavigate } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@mui/material';

// const slideInAnimation = keyframes`
//   0% {
//     opacity: 0;
//     transform: translate(-50%, 100%);
//   }
//   100% {
//     opacity: 1;
//     transform: translate(-50%, -50%);
//   }
// `;

const LandingPageStatic = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [animate, setAnimate] = useState(false);

//   useEffect(() => {
//     setAnimate(true);
//   }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        backgroundImage: 'url(/images/background-image.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '30%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        //   animation: animate ? `${slideInAnimation} 1s ease-out forwards` : 'none',
        //   opacity: 1,
        }}
      >
        <img
          src="/images/LogoV2-800.png"
          alt="Logo"
          style={{
            maxWidth: isMobile ? '100%' : '70%',
            height: 'auto',
          }}
        />
      </Box>
    </Box>
  );
};

export default LandingPageStatic;