import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
  Box, Container,
} from '@mui/material';
import NavBar from './components/NavBar';
import ActivityCardMuiCRUD from './components/ActivityCardMuiCRUD';


const ScheduleAdmin = () => {
  const { id } = useParams(); // Get the activity ID from the URL parameters
  const [activities, setActivities] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
      const fetchActivity = async () => {
          try {
            const response = await fetch(`/api/activities/`);
              // const response = await fetch(`http://localhost:4000/api/activities/`);
              if (!response.ok) {
                  throw new Error(`HTTP error! status: ${response.status}`); // Ensure you throw an error if response is not ok
              }
              const activities = await response.json();
              setActivities(activities);
          } catch (err) {
              setError(err.message);
          } finally {
              setIsLoading(false);
          }
      };
      fetchActivity();
  }, [id]); // Dependency array with ID to refetch if the ID changes

  if (isLoading) {
      return <p>Loading...</p>;
  }

  if (error) {
      return <p>Error: {error}</p>;
  }

  if (!activities) {
      return <p>No activity found.</p>;
  }

//   const handleDelete = async () => {
//     if (window.confirm('Are you sure you want to delete this activity?')) {
//         try {
//             const response = await fetch(`http://localhost:4000/api/activities/${activityData._id}`, {
//                 method: 'DELETE',
//             });
//             if (!response.ok) throw new Error('Failed to delete activity');
            
//             navigate('/admin-schedule'); // Navigate to the list of activities
//         } catch (error) {
//             console.error('Error deleting activity:', error);
//         }
//     }
// };

  // Function to handle delete
  const handleDelete = async (idToDelete) => {
    if (window.confirm('Are you sure you want to delete this activity?')) {
      try {
        const response = await fetch(`/api/activities/${idToDelete}`, {
        // const response = await fetch(`http://localhost:4000/api/activities/${idToDelete}`, {
          method: 'DELETE',
        });
        if (!response.ok) throw new Error('Failed to delete activity');
        // Filter out the deleted activity
         // This will update the state and trigger a re-render of ScheduleAdmin
        setActivities(prevActivities => prevActivities.filter(activity => activity._id !== idToDelete));
        navigate('/admin-schedule'); // Navigate to the list of activities
      } catch (error) {
        console.error('Error deleting activity:', error);
      }
    }
  };


  return (
    <>
   <Container maxWidth="lg" >
       <NavBar />
      
       <Box sx={{ mt: 27 }}> 
      {/* Filter Button Groups */}

        </Box>

      {/* Activity Cards */}
      <Box sx={{ margin: 0, pb: 5 }}>
        {/* Map through your activity data here to render Activity Cards */}
        {activities.map((activity) => (
        <ActivityCardMuiCRUD
          key={activity._id}
          activityData={activity}
          onDelete={() => handleDelete(activity._id)} />
      ))}
        {/* Repeat for other activities */}
      </Box>
    </Container>
    </>
  );
};

export default ScheduleAdmin;
