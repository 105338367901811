import React from 'react';
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';

function Login() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 16 }}>
            <Button
                variant="contained"
                startIcon={<GoogleIcon />}
                href="/auth/google"
                // href="http://localhost:4000/auth/google" // For local development
                style={{ textTransform: 'none' }} // Optional: Prevents uppercase transformation common in MUI buttons
            >
                Welcome. Please log in with Google
            </Button>
        </div>
    );
}

export default Login;