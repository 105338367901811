import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Logo from './components/Logo';
import DaySelect from './components/DaySelect';
import StartTimePicker from './components/StartTimePicker';
import EndTimePicker from './components/EndTimePicker';
import ActivityTypeSelect from './components/ActivityTypeSelect';
// import LogoutButton from './components/LogoutButton';
import { ButtonGroup } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // Needed for UTC support
import timezone from 'dayjs/plugin/timezone'; // For timezone manipulation

dayjs.extend(utc);
dayjs.extend(timezone);

export default function FormPage() {
  const navigate = useNavigate();

  const [activityLabel, setActivityLabel] = React.useState('');
  const [activityDay, setActivityDay] = React.useState('');
  const [startTime, setStartTime] = React.useState(null);
  const [endTime, setEndTime] = React.useState(null);
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    // Convert startTime and endTime to UTC
    // const utcStartTime = dayjs(startTime).tz('America/Los_Angeles').utc().format();
    const utcStartTime = dayjs(startTime).tz('America/Los_Angeles').format();
    // console.log(utcStartTime);
    // const utcEndTime = dayjs(endTime).tz('America/Los_Angeles').utc().format();
    const utcEndTime = dayjs(endTime).tz('America/Los_Angeles').format();
    // console.log(utcEndTime);

    data.append('activityStartTime', utcStartTime);
    data.append('activityEndTime', utcEndTime);

    // if (startTime) {
    //   data.append('activityStartTime', startTime);
    // }
    // if (endTime) {
    //   data.append('activityEndTime', endTime);
    // }
    if (activityLabel) {
      data.append('activityLabel', activityLabel);
    }
    if (activityDay) {
      data.append('activityDay', activityDay);
    }

    try {
      const response = await fetch('/api/activities', {
      // const response = await fetch('http://localhost:4000/api/activities', {
        method: 'POST',
        body: data // Directly pass FormData object, no need to set headers as 'Content-Type': 'multipart/form-data' is automatically set
      });
      if (response.ok) {
        const result = await response.json();
        // console.log('Activity created:', result);
        // console.log('--------------');
        // console.log('result._id:', result._id);
        // console.log('result.activity_id:',result.activity._id)
        // navigate('/schedule'); // Redirect or update UI here
        navigate(`/activity/${result.activity._id}`); // assuming the URL pattern
      } else {
        throw new Error('Failed to create activity');
      }
    } catch (error) {
      console.error('Error creating activity:', error);
    }
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ bgcolor: 'secondary.light', borderRadius: 5 }}>
      <Box sx={{ marginTop: 3, paddingTop: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Logo />
        <Typography component="h1" variant="h5">
          Schedule Activity Form
        </Typography>
        <Box component="form" validate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <FormControl fullWidth>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ButtonGroup variant="contained" color="secondary" aria-label="outlined primary button group" fullWidth sx={{ '& .MuiButton-root': { border: 1, borderColor: 'secondary.dark', borderRadius: 0 } }}>
                  <Button component={Link} to="/schedule">View User Schedule</Button>
                  <Button component={Link} to="/admin-schedule">View Admin Schedule</Button>
                  {/* <LogoutButton>Logout</LogoutButton> */}
                </ButtonGroup>
              </Grid>
              <Grid item xs={12}>
                <TextField required fullWidth id="activityName" label="Activity Name / Title" name="activityName" />
              </Grid>
              <Grid item xs={12}>
                <TextField required fullWidth id="activityType" label="Activity Type / Subtitle" name="activityType" />
              </Grid>
              <Grid item xs={12}>
              <ActivityTypeSelect required={true} activityLabel={activityLabel} setActivityLabel={setActivityLabel} />
              </Grid>
              <Grid item xs={12}>
                <TextField required fullWidth id="activityLocation" label="Activity Location" name="activityLocation" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle" component="h6" gutterBottom sx={{ display: 'flex', pl: 1, justifyContent: 'left' }}>
                  Activity Image*
                </Typography>
                <TextField required fullWidth type="file" id="activityImage" name="activityImage" />
              </Grid>
              <Grid item xs={12}>
              <DaySelect required={true} activityDay={activityDay} setActivityDay={setActivityDay}/>
              </Grid>
              <Grid item xs={6}>
              <StartTimePicker required={true} selectedTime={startTime} setSelectedTime={setStartTime} />
              </Grid>
              <Grid item xs={6}>
              <EndTimePicker required={true} selectedTime={endTime} setSelectedTime={setEndTime} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="activityDescription" label="Description (Optional)" name='activityDescription' multiline rows={4} />
              </Grid>
            </Grid>
            <Button type="submit" fullWidth variant="contained" color="error" sx={{ mt: 3, mb: 2 }}>
              Create Activity
            </Button>
          </FormControl>
        </Box>
      </Box>
    </Container>
  );
}
