import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ActivityCardRow from './components/ActivityCardRow';
import NavBarPublic from './components/NavBarPublic';
import {
    Box, Container, useMediaQuery, useTheme,
  } from '@mui/material';




const ActivityDetailMore = () => {
    const { id } = useParams(); // Get the activity ID from the URL parameters
    const [activity, setActivity] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  


    useEffect(() => {
        const fetchActivity = async () => {
            try {
                const response = await fetch(`/api/activities/${id}`);
                // const response = await fetch(`http://localhost:4000/api/activities/${id}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`); // Ensure you throw an error if response is not ok
                }
                const data = await response.json();
                setActivity(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchActivity();
    }, [id]); // Dependency array with ID to refetch if the ID changes

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    if (!activity) {
        return <p>No activity found.</p>;
    }

    return (
        <>
        <Container maxWidth="lg" sx={{ pb: 5 }} >
        <NavBarPublic />
        <Box sx={{ mt: isSmallScreen ? 12 : 15, mb: 5 }}> 
        </Box>
        <ActivityCardRow
            key={activity.id}
            activityData={activity}
            
        />
        </Container>
        </>
    );
};

export default ActivityDetailMore;
