import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import DaySelect from './components/DaySelect';
import StartTimePicker from './components/StartTimePicker';
import EndTimePicker from './components/EndTimePicker';
import ActivityTypeSelect from './components/ActivityTypeSelect';
import ActivityCardMui from './components/ActivityCardMui';
import NavBar from './components/NavBar';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // Needed for UTC support
import timezone from 'dayjs/plugin/timezone'; // For timezone manipulation

dayjs.extend(utc);
dayjs.extend(timezone);

const UpdateFormPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [startTime, setStartTime] = React.useState(null);
    const [endTime, setEndTime] = React.useState(null);
    
    const [activity, setActivity] = useState({
        activityName: '',
        activityType: '',
        activityLabel: '',
        activityDay: '',
        activityStartTime: null,
        activityEndTime: null,
        activityLocation: '',
        activityDescription: '',
        isFeatured: false,
        isNotFeatured: false
    });

    useEffect(() => {
        const fetchActivity = async () => {
            try {
               const response = await fetch(`/api/activities/${id}`);
                // const response = await fetch(`http://localhost:4000/api/activities/${id}`);
                if (!response.ok) throw new Error('Failed to fetch activity');
                const data = await response.json();
                setActivity(data);
                setStartTime(dayjs(data.activityStartTime).tz('America/Los_Angeles'));
                setEndTime(dayjs(data.activityEndTime).tz('America/Los_Angeles'));
            } catch (error) {
                console.error('Error fetching activity:', error);
            }
        };
        fetchActivity();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setActivity(prev => ({ ...prev, [name]: value }));
    };


    const handleSubmit = async (event) => {
      event.preventDefault();
      const formData = new FormData();
  
      // Convert times and append them to formData
      const utcStartTime = dayjs(startTime).tz('America/Los_Angeles').utc().format();
      const utcEndTime = dayjs(endTime).tz('America/Los_Angeles').utc().format();
  
      formData.append('activityStartTime', utcStartTime);
      formData.append('activityEndTime', utcEndTime);
  
      // Manually append each field only once
      formData.append('activityName', activity.activityName);
      formData.append('activityType', activity.activityType);
      formData.append('activityLabel', activity.activityLabel);
      formData.append('activityDay', activity.activityDay);
      formData.append('activityLocation', activity.activityLocation);
      formData.append('activityDescription', activity.activityDescription);
      formData.append('isFeatured', activity.isFeatured);
      formData.append('isNotFeatured', activity.isNotFeatured);
  
      // Handle the file separately if exists
      if (event.currentTarget.activityImage.files[0]) {
          formData.append('activityImage', event.currentTarget.activityImage.files[0]);
      }
  
      console.log([...formData]); // This will help you see what's being sent correctly.
  
      try {
        const response = await fetch(`/api/activities/${id}`, {
          // const response = await fetch(`http://localhost:4000/api/activities/${id}`, {
              method: 'PUT',
              body: formData
          });
          if (!response.ok) throw new Error(`Failed to update activity: ${response.statusText}`);
          const result = await response.json();
          navigate(`/activity/${id}`);
      } catch (error) {
          console.error('Error updating activity:', error);
      }
  };


  return (
    <>
      <NavBar />
        <Box sx={{ mt: 15 }}> 
        </Box>
    <Container component="main" maxWidth="md" sx={{ bgcolor: 'secondary.light', borderRadius: 5, pb: 10, pt:1 }}>
  
        <ActivityCardMui
            key={activity.id}
            activityData={activity}
            sx={{ pt: 5 }}
        />
    
    <Container maxWidth="xs" sx={{ bgcolor: 'secondary.light', borderRadius: 5 }}>
      
      <Box sx={{ marginTop: 3, paddingTop: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        
        <Typography component="h1" variant="h5">
         Activity Update Form
        </Typography>
        <Typography component="h3" variant="caption">
         You are editing the activity above. Enter only the details you wish to update and click the 'Update Activity' button below.
        </Typography>
        <Box component="form" validate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <FormControl fullWidth>
            <Grid container spacing={2}>
             
              <Grid item xs={12}>
              <TextField
                            fullWidth
                            label="Activity Name"
                            name="activityName"
                            value={activity.activityName}
                            onChange={handleChange}
                        />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Activity Type / Subtitle" name="activityType"  value={activity.activityType} onChange={handleChange} />
              </Grid>
              <Grid item xs={12}>
              <ActivityTypeSelect
                  activityLabel={activity.activityLabel}
                  setActivityLabel={value => setActivity(activity => ({ ...activity, activityLabel: value }))}
              />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Activity Location" name="activityLocation"  value={activity.activityLocation} onChange={handleChange}/>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle" component="h6" gutterBottom sx={{ display: 'flex', pl: 1, justifyContent: 'left' }}>
                  Activity Image*
                </Typography>
                <TextField fullWidth type="file" id="activityImage" name="activityImage" />
              </Grid>
              <Grid item xs={12}>      
                <DaySelect
                  activityDay={activity.activityDay}
                  setActivityDay={value => setActivity(activity => ({ ...activity, activityDay: value }))}
              />
              </Grid>
              <Grid item xs={6}>
              <StartTimePicker
                selectedTime={startTime}
                setSelectedTime={setStartTime}
            />
              </Grid>
              <Grid item xs={6}>
              <EndTimePicker
                selectedTime={endTime}
                setSelectedTime={setEndTime}
            />
              </Grid>
              <Grid item xs={12}>
              
                <TextField fullWidth label="Description (Optional)" name='activityDescription'  value={activity.activityDescription} onChange={handleChange} multiline rows={4} />
              </Grid>
            </Grid>
            <Button type="submit" fullWidth variant="contained" color="error" sx={{ mt: 3, mb: 2 }}>
             Update Activity
            </Button>
          </FormControl>
        </Box>
      </Box>
    </Container>
  </Container>
  </>
  );
}

export default UpdateFormPage;