import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CloseModalButton = ({ onClick }) => (
  <IconButton
    aria-label="close"
    onClick={onClick}
    sx={{
      position: 'absolute',
      right: 8,
      top: 8,
      border: '4px solid #e24935',
      color: (theme) => theme.palette.grey[500],
    }}
  >
    <CloseIcon />
  </IconButton>
);

export default CloseModalButton;