import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from './components/Logo';
import { Box, Button, Typography, Container, keyframes } from '@mui/material'


const slideInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, 100%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
`;


const NoPage = () => {
    const [animate, setAnimate] = React.useState(false);
    useEffect(() => {
        setAnimate(true);
      }, []);
    

    return (

            <Container component="main" maxWidth="xs" sx={{
                        bgcolor: 'secondary.light',
                        border: '5px solid #e24935',
                        borderRadius: 5,
                        display: 'flex', justifyContent: 'center', alignItems: 'center',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        textAlign: 'center',
                        animation: animate ? `${slideInAnimation} 1s ease-out forwards` : 'none',
                        opacity: 0,
                    
                    }}>
             
                  <Box
                    sx={{
                        marginTop: 1,
                        paddingTop: 1,
                        marginBottom: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                         <Logo />
                    <Typography component="h1" variant="h1" sx={{ mt: 1 }}>
                    🦚
                    </Typography>
                    <Typography component="h2" variant="h5">
                    You're in the Wilderness
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 1 }}>
                    <Typography variant="body2" align="center" sx={{ marginBottom: 1, marginTop: 3 }}>
                        404 = Page not found...
                    </Typography>
                    <Button variant="contained" color="error"  component={Link} to="/">Back to Civilization</Button>
                   
                    </Box>
                 
                </Box>
            
            </Container>

    );
}

export default NoPage;