import { ButtonGroup, Button } from '@mui/material';

const ButtonGroupDays = ({ setDayFilter, currentDayFilter }) => {
  // This function handles changing the day filter
  const handleDayChange = (day) => {
    // console.log('Day Changed to:', day);
    setDayFilter(day);
  };

  return (
    <ButtonGroup variant="contained" color="secondary" aria-label="day button group" fullWidth sx={{'& .MuiButton-root': { border: 1, borderColor: 'secondary.dark', borderRadius: 0 } }}>
      <Button onClick={() => handleDayChange('Friday')} sx={{ backgroundColor: currentDayFilter === 'Friday' ? 'secondary.dark' : 'secondary.main' }}>
        Friday
      </Button>
      <Button onClick={() => handleDayChange('Saturday')} sx={{ backgroundColor: currentDayFilter === 'Saturday' ? 'secondary.dark' : 'secondary.main' }}>
        Saturday
      </Button>
      <Button onClick={() => handleDayChange('Sunday')} sx={{ backgroundColor: currentDayFilter === 'Sunday' ? 'secondary.dark' : 'secondary.main' }}>
        Sunday
      </Button>
      {/* <Button onClick={() => handleDayChange('All')} sx={{ backgroundColor: currentDayFilter === 'All' ? 'secondary.dark' : 'secondary' }}>
        All
      </Button> */}
    </ButtonGroup>
  );
};

export default ButtonGroupDays;