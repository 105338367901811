import React, { useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

function ActivityTypeSelect({ activityLabel, setActivityLabel, required }) {
  const [touched, setTouched] = useState(false);
  const isValid = activityLabel !== '';

  const handleChange = (event) => {
    setActivityLabel(event.target.value);
    setTouched(true);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth required={required} error={touched && !isValid}>
        <InputLabel id="activityLabel-label">Activity Label</InputLabel>
        <Select
          labelId="activityLabel-label"
          id="activityLabel"
          value={activityLabel}
          label="Choose Label"
          onChange={handleChange}
          onBlur={() => setTouched(true)}
        >
          <MenuItem value="Music">Music</MenuItem>
          <MenuItem value="Activities">Activities</MenuItem>
          <MenuItem value="Art">Art</MenuItem>
          <MenuItem value="Vendors">Vendors</MenuItem>
        </Select>
        {touched && !isValid && <FormHelperText>This field is required.</FormHelperText>}
      </FormControl>
    </Box>
  );
}

export default ActivityTypeSelect;
