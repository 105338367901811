import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ImageZoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import PropTypes from 'prop-types';
import {
    AppBar,
    Toolbar,
    IconButton,
    ButtonGroup,
    Button,
    Box,
    ListItemText,
    List,
    ListItem,
    ListItemButton,
    Divider,
    Drawer,
    Modal,
    Typography,
    useTheme,
    useMediaQuery,
  } from '@mui/material';
// import ButtonGroupTop from './ButtonGroupTop';
import ButtonGroupDays from './ButtonGroupDays';
import ButtonGroupActivities from './ButtonGroupActivities';

// import CssBaseline from '@mui/material/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from './Logo';
import CloseModalButton from './CloseModalButton';
import CloseDrawerButton from './CloseDrawerButton';

const drawerWidth = 180;
const drawerHeight = 90;

const navItems = [
    // { text: 'Welcome' },
    { text: 'Event Map' },
    // { text: 'Help Desk' },
  ];

  const EventMapModal = ({ open, handleClose }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
    return (
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            width: isMobile ? '75%' : '70%',
            maxWidth: '1000px',
            height: isMobile ? '87.5%' : 'auto',
            maxHeight: '90%',
            overflowY: 'auto',
            border: '4px solid #e24935',
            borderRadius: 2,
          }}
        >
          <CloseModalButton onClick={handleClose} />
          <Typography variant="h1" component="h1" gutterBottom sx={{ display: 'flex', mt: 2, fontSize: '2.25rem', textTransform: 'none',  justifyContent: 'center',
              alignItems: 'center', }}> 
            Event Map
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '75%',
            }}
          >
            <ImageZoom>
              <img src="/images/map2.jpg" alt="Event Map" width="100%" />
            </ImageZoom>
            
           
          </Box>
          <Box sx={{ display: 'flex', mt: 2,  justifyContent: 'center',
              alignItems: 'center', }}>
            <Button variant='contained' color='warning' href="/images/map2.jpg" target='_blank' >
              Download Map
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  const WelcomeModal = ({ open, handleClose }) => (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Welcome
        </Typography>
        {/* Add your event map content here */}
      </Box>
    </Modal>
  );
  // function DrawerAppBar (props) {
    function DrawerAppBar({ setDayFilter, setActivityTypeFilter, dayFilter, activityTypeFilter  }) {
      const navigate = useNavigate();
      const [mobileOpen, setMobileOpen] = useState(false);
      const [openModals, setOpenModals] = useState({
          'Event Map': false,
          'Welcome': false,
      });
  
      const handleItemClick = (item) => {
          setOpenModals((prevState) => ({
              ...prevState,
              [item.text]: true,
          }));
      };
  
      const handleModalClose = (modalName) => {
          setOpenModals((prevState) => ({
              ...prevState,
              [modalName]: false,
          }));
      };
  
      const handleDrawerToggle = () => {
          setMobileOpen(!mobileOpen);
      };
    
    const handleRefresh = () => {
      // Navigate programmatically and force reload
      navigate(0);
    };

const drawer = (
  
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', position: 'relative'  }}>  
    <CloseDrawerButton onClick={handleDrawerToggle} />
    <Divider />
    <List>
        {navItems.map((item) => (
        <ListItem key={item.text} disablePadding onClick={() => handleItemClick(item)}>
            <ListItemButton sx={{ textAlign: 'center' }}>
            <ListItemText primary={item.text} />
            </ListItemButton>
        </ListItem>
        ))}
    </List>
    </Box>
);

  // const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav" color="secondary">
        <Toolbar>
         <Logo />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ ml: 'auto', width: 48, height: 48  }} // Aligns the icon button to the right
          >
            <MenuIcon sx={{ ml: 'auto', width: 40, height: 40  }} />
          </IconButton>
        </Toolbar>
        <Box sx={{ mt: 1 }}> 

      {/* Filter Button Groups */}
      <ButtonGroup variant="contained" color="secondary" aria-label="outlined primary button group" fullWidth sx={{'& .MuiButton-root': { border: 1, borderColor: 'secondary.dark', borderRadius: 0 } }}>
          <Button component={Link} to="/schedule" onClick={handleRefresh}> <Typography variant="h1" component="div" sx={{ fontSize: '1.25rem', textTransform: 'none' }}>  {/* Set fontSize as needed */}
          Festival Schedule
        </Typography></Button>
        </ButtonGroup>
        
        {/* <ButtonGroupTop setFilter={setFilter} currentFilter={filter} /> */}
        <ButtonGroupDays setDayFilter={setDayFilter} currentDayFilter={dayFilter} />
        <ButtonGroupActivities setActivityTypeFilter={setActivityTypeFilter} currentActivityTypeFilter={activityTypeFilter} />


        </Box>
      </AppBar>
      <Drawer
        // container={container}
        variant="temporary"
        open={mobileOpen}
        anchor='right'
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, height: drawerHeight, backgroundColor: 'secondary.main', color: 'secondary.contrastText',
        borderBottomLeftRadius: 20, paddingTop: 1, borderBottom: '8px double #e24935', borderLeft: '8px double #e24935', overflow: 'visible',},
        }}
      >
        {drawer}
      </Drawer>
      <WelcomeModal
        open={openModals['Welcome']}
        handleClose={() => handleModalClose('Welcome')}
      />
      <EventMapModal
        open={openModals['Event Map']}
        handleClose={() => handleModalClose('Event Map')}
      />
    </Box>
    
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;
