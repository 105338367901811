import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ActivityCardMuiCRUD from './components/ActivityCardMuiCRUD';
import {
    Box, Container,
  } from '@mui/material';
import NavBar from './components/NavBar';



const ActivityDetail = (onDelete) => {
    const { id } = useParams(); // Get the activity ID from the URL parameters
    const [activity, setActivity] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchActivity = async () => {
            try {
              const response = await fetch(`/api/activities/${id}`);
                // const response = await fetch(`http://localhost:4000/api/activities/${id}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`); // Ensure you throw an error if response is not ok
                }
                const data = await response.json();
                setActivity(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchActivity();
    }, [id]); // Dependency array with ID to refetch if the ID changes

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    if (!activity) {
        return <p>No activity found.</p>;
    }

     // Function to handle delete
  const handleDelete = async (idToDelete) => {
    if (window.confirm('Are you sure you want to delete this activity?')) {
      try {
        const response = await fetch(`/api/activities/${idToDelete}`, {
        // const response = await fetch(`http://localhost:4000/api/activities/${idToDelete}`, {
          method: 'DELETE',
        });
        if (!response.ok) throw new Error('Failed to delete activity');
        // Filter out the deleted activity
         // This will update the state and trigger a re-render of ScheduleAdmin
        navigate('/admin-schedule'); // Navigate to the list of activities
      } catch (error) {
        console.error('Error deleting activity:', error);
      }
    }
  };

    return (
        <>
        <Container maxWidth="lg" >
        <NavBar />
        <Box sx={{ mt: 15 }}> 
        </Box>
        <ActivityCardMuiCRUD
            key={activity.id}
            activityData={activity}
            onDelete={() => handleDelete(activity._id)}
            
        />
        </Container>
        </>
    );
};

export default ActivityDetail;
