import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const StartTimePicker = ({ selectedTime, setSelectedTime, required }) => {
  const [touched, setTouched] = useState(false);
  const isValid = required ? selectedTime != null : true;

  const handleTimeChange = (time) => {
    setSelectedTime(time);
    setTouched(true); // Mark as touched when the user selects a time
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label="Activity Start Time"
        value={selectedTime}
        onChange={handleTimeChange}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            error={touched && required && !isValid}
            helperText={touched && required && !isValid ? "Start time is required" : "Adjust time"}
            fullWidth
            onBlur={() => setTouched(true)} // Mark as touched on blur
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default StartTimePicker;
