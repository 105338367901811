import React from 'react';
import { Container, Box } from '@mui/material';
import ActivityCardMui from './components/ActivityCardMui';
import StatusCardPast from './components/StatusCardPast';  // Assuming this is the correct import for your past activities card
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter); 

const ActivityList = ({ activities, isLoading, error }) => {
  // Assume the current timezone is set correctly somewhere, or use a config
  const currentDate = dayjs().tz('America/Los_Angeles');

  // Split activities into past and current/future
  const pastActivities = activities.filter(activity => 
    dayjs(activity.activityEndTime).tz('America/Los_Angeles').isBefore(currentDate)
  );
  const upcomingActivities = activities.filter(activity => 
    dayjs(activity.activityEndTime).tz('America/Los_Angeles').isSameOrAfter(currentDate)
  );

  return (
    <Container maxWidth="lg">
        <Box sx={{ mt: 26 }}>
            {/* This is a spacer box */}
        </Box>
        <Box sx={{ mt: 3 }}>
            {isLoading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>Error: {error}</p>
            ) : (
                <>
                  {/* Display upcoming or current activities */}
                  <Box sx={{ margin: 0, pb: 1 }}>
                      {upcomingActivities.map(activity => (
                        <ActivityCardMui key={activity._id} activityData={activity} />
                      ))}
                  </Box>

                  {/* Display past activities if any */}
                  {pastActivities.length > 0 && (
                    <>
                      <StatusCardPast />
                      <Box sx={{ margin: 0, pb: 5 }}>
                          {pastActivities.map(activity => (
                            <ActivityCardMui key={activity._id} activityData={activity} />
                          ))}
                      </Box>
                    </>
                  )}
                </>
            )}
        </Box>
    </Container>
  );
};

export default ActivityList;