import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'; 
import { AuthProvider } from './context/AuthContext';
import { ProtectedElement } from './components/ProtectedRoute';
// import ActivityList from './ActivityList';
// import Schedule from './Schedule';
import ScheduleAdmin from './ScheduleAdmin';
import LandingPageStatic from './LandingPageStatic';
// import LandingAnimationTest from './LandingAnimationTest';
import LandingRedirect from './LandingRedirect';
import FormPage from './FormPage';
import UpdateFormPage from './UpdateFormPage';
import LoginPage from './LoginPage';
import ErrorPage from './ErrorPage';
import NotFound from './NotFound';
import ActivityMore from './ActivityMore';
import ActivityDetail from './ActivityDetail';
import ScheduleManager from './ScheduleManager';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif', // Default font for the whole app
    h1: {
      fontFamily: 'Lobster Two, Arial, sans-serif', // Specific font for h1
    },
  },
  palette: {
    primary: {
      light: '#d2ece7',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fde0da',
      main: '#1f8189',
      dark: '#1a6e74',
      contrastText: '#fff',
    },
    error: {
      light: '#fbf0e3',
      main: '#e406bd',
      dark: '#f50057',
      contrastText: '#fff',
    },
  },
});

  function App() {
   
  return (
    <ThemeProvider theme={theme}>
    <AuthProvider>
    <Router>
      <Routes> 
      <Route path="/" element={<LandingPageStatic />} /> 
       {/* <Route path="/" element={<LandingRedirect />} />  Home page with redirect to the schedule */} 
       <Route path="/login" element={<LoginPage />} />
       <Route path="/landing" element={<LandingRedirect />} /> 
       <Route path="/static" element={<LandingPageStatic/>} /> 
        {/* <Route path="/activity-form" element={<FormPage />} /> */}
        <Route path="/schedule" element={<ScheduleManager />} />
        <Route path="/bird/:id" element={<ActivityMore />} />
        <Route path="/error" element={<ErrorPage />} /> 
        {/* Protected Routes */}
        <Route path="/form" element={<ProtectedElement><FormPage /></ProtectedElement>} />
        <Route path="/admin-schedule" element={<ProtectedElement><ScheduleAdmin /></ProtectedElement>} /> 
        <Route path="/activity/:id" element={<ProtectedElement><ActivityDetail /></ProtectedElement>} />
        <Route path="/update-form/:id" element={<ProtectedElement><UpdateFormPage/></ProtectedElement>} />

        {/* Catch All */}
        <Route path="*" element={<NotFound />} />

      </Routes>
    </Router>
    </AuthProvider>
    </ThemeProvider>
  );
}

export default App;