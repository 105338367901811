// Logo.js
import React from 'react';
import { Box } from '@mui/material';

const Logo = () => {
  return (
    <Box
      component="img"
      sx={{
        height: 64,
        width: 'auto',
        maxWidth: { xs: 200, md: 300 },
        paddingTop: 1,
      }}
      alt="Logo"
      src="/images/Boaf-Logo.png"
      href="/schedule"
    />
  );
};

export default Logo;