import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import DrawerAppBar from './components/DrawerAppBar';
import ActivityList from './ActivityList';

dayjs.extend(utc);
dayjs.extend(timezone);

const ScheduleManager = () => {
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dayFilter, setDayFilter] = useState(getCurrentDayFilter());
  const [activityTypeFilter, setActivityTypeFilter] = useState('All');

  function getCurrentDayFilter() {
    const now = dayjs().tz('America/Los_Angeles');
    // console.log(now.format('dddd'));
    const dayOfWeek = now.format('dddd');

    switch (dayOfWeek) {
        case 'Friday':
        case 'Saturday':
        case 'Sunday':
            return dayOfWeek;
        default:
            return 'Friday';
    }
}

  useEffect(() => {
      const fetchActivities = async () => {
          const query = new URLSearchParams({
              day: dayFilter,
              type: activityTypeFilter
          }).toString();
          const url = `/api/activities?${query}`;
        //   const url = `http://localhost:4000/api/activities?${query}`;
          try {
              const response = await fetch(url);
              if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
              const data = await response.json();
              setActivities(data);
          } catch (err) {
              setError(err.message);
          } finally {
              setIsLoading(false);
          }
      };
      // console.log('Day Filter Changed:', dayFilter);
      // console.log('Activity Type Filter Changed:', activityTypeFilter);
      fetchActivities();
  }, [dayFilter, activityTypeFilter]);
  
    return (
      <>
        <DrawerAppBar
                setDayFilter={setDayFilter}
                setActivityTypeFilter={setActivityTypeFilter}
                dayFilter={dayFilter}
                activityTypeFilter={activityTypeFilter}
            />
            <ActivityList
                activities={activities}
                isLoading={isLoading}
                error={error}
            />
      </>
    );
  };
  
  export default ScheduleManager;