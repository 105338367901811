import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CloseDrawerButton = ({ onClick }) => (
  <IconButton
    aria-label="close"
    onClick={onClick}
    sx={{
      position: 'absolute',
      left: -28,
      top: -4,
      zIndex: 1,
      color: (theme) => theme.palette.grey[500],
      border: '4px solid #e24935',
      backgroundColor: 'background.paper',
      boxShadow: 1,
      '&:hover': {
        backgroundColor: 'background.paper',
      },
    }}
  >
    <CloseIcon />
  </IconButton>
);

export default CloseDrawerButton;