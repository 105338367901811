import React, { useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

function DaySelect({ activityDay, setActivityDay, required }) {
  const [touched, setTouched] = useState(false);
  const isValid = activityDay !== '';

  const handleChange = (event) => {
    setActivityDay(event.target.value);
    setTouched(true);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
       <FormControl fullWidth required={required} error={touched && !isValid}>
        <InputLabel id="activityDay-label">Choose Day</InputLabel>
        <Select
          labelId="activityDay-label"
          id="activityDay"
          value={activityDay}
          label="Choose Day"
          onChange={handleChange}
          onBlur={() => setTouched(true)}
        >
          <MenuItem value="Friday">Friday</MenuItem>
          <MenuItem value="Saturday">Saturday</MenuItem>
          <MenuItem value="Sunday">Sunday</MenuItem>
          <MenuItem value="All">All Days</MenuItem>  
          {/* May come back to the All feature if I want later */}
        </Select>
        {touched && !isValid && <FormHelperText>This field is required.</FormHelperText>}
      </FormControl>
    </Box>
  );
}

export default DaySelect;