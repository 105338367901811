import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext({ user: null, loading: false, error: null });

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                setLoading(true);
                const response = await fetch('/auth/status', { credentials: 'include' });
                // const response = await fetch('http://localhost:4000/auth/status', { credentials: 'include' });
                const data = await response.json();
                if (data.isAuthenticated) {
                    setUser(data.user);
                } else {
                    setUser(null);
                }
                setError(null);
            } catch (error) {
                console.error('Failed to check authentication status:', error);
                setError(error);
                setUser(null);
            } finally {
                setLoading(false);
            }
        };

        checkAuthStatus();
    }, []);

    return (
        <AuthContext.Provider value={{ user, loading, error }}>
            {children}
        </AuthContext.Provider>
    );
};
