import React from 'react';
import { Link} from 'react-router-dom';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Box,
  useTheme,
  useMediaQuery,
  ButtonGroup,
  Button 
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';



const ActivityCardMuiCRUD = ({ activityData, onDelete, onFavoriteToggle }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // const navigate = useNavigate();
  
  const {
    _id,
    activityName,
    activityType,
    activityDay,
    startTimeFormatted,
    endTimeFormatted,
    activityLocation,
    activityDescription,
    activityImageTransformed,
    isFavorite,
  } = activityData;

  const activityLink = (
    <Link to={`/bird/${_id}`}>
      <IconButton aria-label="delete" size="small" sx={{color: 'primary.main', fontSize: '0.65rem', p: 0, m: 0}}>
      <Typography variant="caption" component="p" sx={{color: 'primary.main', fontSize: '0.65rem', p: 0, m: 0}}>Learn More</Typography>
      <KeyboardDoubleArrowRightIcon sx={{color: 'primary.main', fontSize: '0.75rem'}} />
      </IconButton>
    </Link>
  );
  
  return (
    <Card sx={{
      display: 'flex',
      flexDirection: isSmallScreen ? 'row' : 'row',
      width: '100%',
      overflow: 'hidden',
      mb: isSmallScreen ? 1 : 2,
      mt: isSmallScreen ? 1 : 2,
      bgcolor: 'error.light',
      border: isSmallScreen ? '2px solid #f50057' : '3px solid #f50057',
      borderRadius: '10px',

    }}>
      <CardMedia
        component="img"
        sx={{
          width: isSmallScreen ? 110 : '30%',
          height: isSmallScreen ? 110 : 'auto',
          objectFit: 'contain',
        }}
        image={activityImageTransformed}
        alt={`${activityName} image`}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, position: 'relative' }}>
        <CardContent sx={{ flex: '1 0 auto', padding: isSmallScreen ? 1 : 2, }}>

        {/* TITLE - activityName */}
        <Typography
        component="div"
        sx={{
          typography: {
            xs: 'h1',       
            sm: 'h1',          
            md: 'h1',          
            lg: 'h1',          
            xl: 'h1',          
          },
          fontSize: {
            xs: '0.75rem',   // custom size for extra small screens
            sm: '1rem',  // custom size for small screens
            md: '2rem',      // custom size for medium screens
            lg: '1.25rem',   // custom size for large screens
            xl: '2.35rem',    // custom size for extra large screens
          },
          noWrap: true,
          color: 'primary.main',
        }}
      >
            {activityName}
          </Typography>

          {/* Subtitile  */}
          <Typography
        component="div"
        sx={{
          typography: {
            xs: 'caption',       // theme.typography.body1 for extra-small screens
            sm: 'h3',          // theme.typography.h6 for small screens and up
            md: 'h5',          // theme.typography.h5 for medium screens and up
            lg: 'caption',          // theme.typography.h4 for large screens and up
            xl: 'caption',          // theme.typography.h3 for extra-large screens and up
          },
          fontSize: {
            xs: '0.65rem',   // custom size for extra small screens
            sm: '1.05rem',  // custom size for small screens
            md: '1.25rem',      // custom size for medium screens
            lg: '1.25rem',   // custom size for large screens
            xl: '1.75rem',    // custom size for extra large screens
          },
          noWrap: true,
          color: 'error.dark',
        }}
      >
            {activityType}
          </Typography>

          {/* Date / Time /  Location */}
          <Typography
        component="div"
        sx={{
          typography: {
            xs: 'h3',       // theme.typography.body1 for extra-small screens
            sm: 'h6',          // theme.typography.h6 for small screens and up
            md: 'h5',          // theme.typography.h5 for medium screens and up
            lg: 'caption',          // theme.typography.h4 for large screens and up
            xl: 'h4',          // theme.typography.h3 for extra-large screens and up
          },
          fontSize: {
            xs: '0.65rem',   // custom size for extra small screens
            sm: '0.875rem',  // custom size for small screens
            md: '1rem',      // custom size for medium screens
            lg: '1.25rem',   // custom size for large screens
            xl: '1.5rem',    // custom size for extra large screens
          },
          noWrap: true, pt: '3px', color: 'primary.main',
        }}
      >
         {activityDay.includes('All') ? (
          `All Days | ${activityLocation}`
        ) : (
          `${activityDay} | ${startTimeFormatted} - ${endTimeFormatted} | ${activityLocation}`
        )}
          </Typography>

          {/* Description Area */}
          {/* Apply truncation to the description if it's too long */}
          <Typography
        component="div"
        sx={{
          typography: {
            xs: 'caption',       // theme.typography.body1 for extra-small screens
            sm: 'h6',          // theme.typography.h6 for small screens and up
            md: 'h6',          // theme.typography.h5 for medium screens and up
            lg: 'caption',          // theme.typography.h4 for large screens and up
            xl: 'h5',          // theme.typography.h3 for extra-large screens and up
          },
          fontSize: {
            xs: '0.65rem',   // custom size for extra small screens
            sm: '0.875rem',  // custom size for small screens
            md: '1rem',      // custom size for medium screens
            lg: '1.25rem',   // custom size for large screens
            xl: '1.25rem',    // custom size for extra large screens
          },
          noWrap: true, color: 'primary.main', overflow: 'hidden', pt:'6px', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 8, WebkitBoxOrient: 'vertical' 
        }}
      >
    
          {/* {isSmallScreen ? <> {activityDescription} Learn More{activityLink}</> : activityDescription} */}
          {activityDescription.length > 25 ? (
    isSmallScreen ? (
      activityLink
    ) : (
      activityDescription
    )
  ) : activityDescription.length === 0 ? (
    isSmallScreen ? (
      activityDescription
    ) : (
      activityDescription
    )
  ) : (
    activityDescription
  )}
          </Typography>
        </CardContent>
        <Box sx={{ position: 'absolute', top: 2, right: 2 }}>
          <IconButton onClick={() => onFavoriteToggle(!isFavorite)}>
            <FavoriteIcon color={isFavorite ? 'error' : 'default'} 
            sx={{
              fontSize: {
                xs: 20, // 20px on extra-small screens
                sm: 25, // 25px on small screens
                md: 30, // 30px on medium screens
                lg: 35, // 35px on large screens
                xl: 40  // 40px on extra-large screens
              }
            }}/>
          </IconButton>
        </Box>
        <ButtonGroup variant="contained" color="secondary" aria-label="outlined primary button group" fullWidth sx={{ '& .MuiButton-root': { border: 1, borderColor: 'secondary.dark', borderRadius: 0 } }}>
                  <Button component={Link} to="/form">Create New</Button>
                  <Button component={Link} to={`/update-form/${_id}`} color="primary">Edit</Button>
                 <Button onClick={() => onDelete(activityData._id)} color="error">Delete</Button>

       </ButtonGroup>
      </Box>
     
    </Card>

  );
};

export default ActivityCardMuiCRUD;
