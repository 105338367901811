import * as React from 'react';
// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import { useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  // IconButton,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';




const ActivityCardRow = ({ activityData, onFavoriteToggle }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // const [activities, setActivities] = useState(null);
  // const [isLoading, setIsLoading] = useState(true);
  // const [error, setError] = useState(null);
  
  
  const {
    activityName,
    activityType,
    activityDay,
    startTimeFormatted,
    endTimeFormatted,
    activityLocation,
    activityDescription,
    activityImageTransformed,
  } = activityData;

  // const activityLink = (
  //   <Link to={`/activity/${_id}`}>
  //     <IconButton aria-label="delete" size="small">
  //       <KeyboardDoubleArrowRightIcon fontSize="inherit" />
  //     </IconButton>
  //   </Link>
  // );

//   useEffect(() => {
//     const fetchActivity = async () => {
//         try {
//           // const response = await fetch(`/api/activities/`);
//             const response = await fetch(`http://localhost:4000/api/activities/`);
//             if (!response.ok) {
//                 throw new Error(`HTTP error! status: ${response.status}`); // Ensure you throw an error if response is not ok
//             }
//             const activities = await response.json();
//             setActivities(activities);
//         } catch (err) {
//             setError(err.message);
//         } finally {
//             setIsLoading(false);
//         }
//     };
//     fetchActivity();
// }, []); // Dependency array with ID to refetch if the ID changes

// if (isLoading) {
//     return <p>Loading...</p>;
// }

// if (error) {
//     return <p>Error: {error}</p>;
// }

// if (!activities) {
//     return <p>No activity found.</p>;
// }


  

  // A utility function to truncate text based on character count
  // const truncateText = (text, charLimit, id) => {
  //   if (text.length <= charLimit) return text;
  
  //   const truncatedText = text.slice(0, charLimit) + '...';
  //   return (
  //     <>
  //       {truncatedText}
  //       <Link to={`/path/to/item/${id}`}>
  //         <IconButton aria-label="delete" size="small">
  //           <KeyboardDoubleArrowRightIcon fontSize="inherit" />
  //         </IconButton>
  //       </Link>
  //     </>
  //   );
  // };

  return (
    <Card sx={{
      display: 'flex',
      flexDirection: isSmallScreen ? 'column' : 'row',
      width: '100%',
      overflow: 'hidden',
      mb: isSmallScreen ? 1 : 2,
      mt: isSmallScreen ? 1 : 2,
      bgcolor: 'secondary.light',
      border: '3px solid #f50057',
      borderRadius: '10px',
    }}>
      <CardMedia
        component="img"
        sx={{
          width: isSmallScreen ? '100%' : '30%',
          height: isSmallScreen ? '100%' : 'auto',
          objectFit: 'contain',
        }}
        image={activityImageTransformed}
        alt={`${activityName} image`}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, position: 'relative' }}>
        
        <CardContent sx={{ flex: '1 0 auto', padding: isSmallScreen ? 1 : 2, }}>

        {/* TITLE - activityName */}
        <Typography
        component="div"
        sx={{
          typography: {
            xs: 'h1',       
            sm: 'h1',          
            md: 'h1',          
            lg: 'h1',          
            xl: 'h1',          
          },
          fontSize: {
            xs: '1.5rem',   // custom size for extra small screens
            sm: '1rem',  // custom size for small screens
            md: '2rem',      // custom size for medium screens
            lg: '1.25rem',   // custom size for large screens
            xl: '2.35rem',    // custom size for extra large screens
          },
          noWrap: true,
          color: 'primary.main',
        }}
      >
            {activityName}
          </Typography>

          {/* Subtitile  */}
          <Typography
        component="div"
        sx={{
          typography: {
            xs: 'caption',       // theme.typography.body1 for extra-small screens
            sm: 'h3',          // theme.typography.h6 for small screens and up
            md: 'h5',          // theme.typography.h5 for medium screens and up
            lg: 'caption',          // theme.typography.h4 for large screens and up
            xl: 'caption',          // theme.typography.h3 for extra-large screens and up
          },
          fontSize: {
            xs: '0.95rem',   // custom size for extra small screens
            sm: '1.05rem',  // custom size for small screens
            md: '1.25rem',      // custom size for medium screens
            lg: '1.25rem',   // custom size for large screens
            xl: '1.75rem',    // custom size for extra large screens
          },
          noWrap: true,
          color: 'error.dark',
        }}
      >
            {activityType}
          </Typography>

          {/* Date / Time /  Location */}
          <Typography
        component="div"
        sx={{
          typography: {
            xs: 'subtitle2',       // theme.typography.body1 for extra-small screens
            sm: 'h6',          // theme.typography.h6 for small screens and up
            md: 'h5',          // theme.typography.h5 for medium screens and up
            lg: 'caption',          // theme.typography.h4 for large screens and up
            xl: 'h4',          // theme.typography.h3 for extra-large screens and up
          },
          fontSize: {
            xs: '0.85rem',   // custom size for extra small screens
            sm: '0.875rem',  // custom size for small screens
            md: '1rem',      // custom size for medium screens
            lg: '1.25rem',   // custom size for large screens
            xl: '1.5rem',    // custom size for extra large screens
          },
          noWrap: true, pt: '1px', color: 'primary.main',
        }}
      >
         {activityDay.includes('All') ? (
          `All Days | ${activityLocation}`
        ) : (
          `${activityDay} | ${startTimeFormatted} - ${endTimeFormatted} | ${activityLocation}`
        )}
          </Typography>

          {/* Description Area */}
          {/* Apply truncation to the description if it's too long */}
          <Typography
        component="div"
        sx={{
          typography: {
            xs: 'subtitle2',       // theme.typography.body1 for extra-small screens
            sm: 'h6',          // theme.typography.h6 for small screens and up
            md: 'h6',          // theme.typography.h5 for medium screens and up
            lg: 'caption',          // theme.typography.h4 for large screens and up
            xl: 'h5',          // theme.typography.h3 for extra-large screens and up
          },
          fontSize: {
            xs: '0.65rem',   // custom size for extra small screens
            sm: '0.875rem',  // custom size for small screens
            md: '1rem',      // custom size for medium screens
            lg: '1.25rem',   // custom size for large screens
            xl: '1.25rem',    // custom size for extra large screens
          },
          noWrap: true, overflow: 'hidden', pt:'6px', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 8, WebkitBoxOrient: 'vertical' 
        }}
      >
    
          {/* {isSmallScreen ? <> {activityDescription} Learn More{activityLink}</> : activityDescription} */}
          {activityDescription}
          </Typography>
        </CardContent>
        {/* <Box sx={{ position: 'absolute', top: 2, right: 2 }}>
          <IconButton onClick={() => onFavoriteToggle(!isFavorite)}>
            <FavoriteIcon color={isFavorite ? 'error' : 'default'} 
            sx={{
              fontSize: {
                xs: 20, // 20px on extra-small screens
                sm: 25, // 25px on small screens
                md: 30, // 30px on medium screens
                lg: 35, // 35px on large screens
                xl: 40  // 40px on extra-large screens
              }
            }}/>
          </IconButton>
        </Box> */}
    
      </Box>
     
    </Card>

  );
};

export default ActivityCardRow;
